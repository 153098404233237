<template>

<app-content :loading="is.loading">

	<app-content-head :title="(isNew) ? 'Add volunteer' : item.badge.name" :back="backPath" :readonly="$authorised('con/volunteers/access', 'read')" v-if="!is.loading">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/volunteers/access', 'read')">

			<app-input-suggest :locked="!isNew" label="Badge" placeholder="Search badges..." v-model="model.badge" :exclude="references.exclude" api="convention/badge" :validation="$v.model.badge" />
			<app-input-suggest label="Slot" placeholder="Search slots..." v-model="model.slot" api="convention/volunteers/slot" :params="{role: references.role.id, status: $constants.volunteers.slot.status.open}" :validation="$v.model.slot" v-if="references.role.type === $constants.volunteers.role.type.schedule" />
			
		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/volunteers/access', 'read'),
			references: {
				role: false,
				exclude: []
			},
			model: {
				id: false,
				badge: false,
				slot: false
			}
		}

	},

	validations: {
		model: {
			badge: {
				required
			},
			slot: {
				required: requiredIf('requiresSlot')
			}
		}
	},

	computed: {

		requiresSlot: function() {

			return this.references.role.type === this.$constants.volunteers.role.type.schedule

		},

		backPath: function() {

			if (this.$route.query.redirect === 'slot') {

				return {
					name: 'Convention.Volunteers.Slots.Edit',
					params: {
						id: this.$route.query.slot,
						role: this.$route.query.role,
					}
				}

			} else if (this.$route.query.redirect === 'badge') {

				return {
					name: 'Convention.Volunteers.Badges.Edit',
					params: {
						id: this.$route.query.badge
					}
				}

			} else {

				return {
					name: this.$route.meta.back
				}

			}

		}

	},

	methods: {

		onSaved: function() {

			this.$router.push(this.backRoute)

		}

	}

}

</script>

<style scoped>

</style>